import { Component, OnInit } from '@angular/core';
import { WebService } from '../../services/web.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private webService: WebService, private sanitizer: DomSanitizer) { }

  link: string;
  ngOnInit() {
    this.getDashboard();
  }
  getDashboard() {
    this.webService.dashboard().subscribe(success => {
      this.link = success.data[0].dashboard;
    }, error => {
      console.log(error);
    });
  }
  getUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
  }
}
