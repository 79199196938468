<app-image-header></app-image-header>
<div class="login-form">
  <form [formGroup] = "myform" (submit)="onSignUp($event)">
    <h2 class="text-center">Sign up</h2>
    <div class="form-group">
      <label for="email" class="required">Email</label>
      <input formControlName="email" type="text" id="email" class="form-control" placeholder="Email" required="required">
    </div>
    <div class="form-group">
      <label for="password" class="required">Password</label>
      <input (click)="this.hasError = false" formControlName="password" type="password" id="password" class="form-control" placeholder="Password" required="required">
    </div>
    <div class="form-group">
      <label for="confirmPassword" class="required">Confirm Password</label>
      <input (click)="this.hasError = false" formControlName="confirmPassword" type="password" id="confirmPassword" class="form-control" placeholder="Confirm Password" required="required">
    </div>
    <div *ngIf="this.myform.valid" class="form-group">
      <button class="btn btn-primary btn-block">Sign up</button>
    </div>
  </form>
  <br>
  <div *ngIf="hasError">
    {{error}}
  </div>
  <div *ngIf="responseReceived">
    <button routerLink="/" class="btn btn-success btn-block">Sign up successful! Log in</button>
  </div>
  <div *ngIf="signUpUnsuccessful">
    The sign up was unsuccessful. Please check your details and try again.
  </div>
</div>
