<app-navbar></app-navbar>
<div class="container">
  <br>
  <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            iComply2 Advice Templates
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Fact%20Find%20Template%20(.doc).docx" target="blank">Fact Find Template (word doc)</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/Client%20Applications%20Checklist.pdf" target="blank">iComply2 Client Application Checklist</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20SoA%20Project%20Template.csv" target="blank">Authority to Access Information Letter</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/SoA%20Code%20Flow%20Diagram.pdf" target="blank">SoA Code Flow Diagram</a></li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-header">
            iComply2 Compliance Reports (Samples)
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/Fact%20Find%20Report%20(Sample).pdf" target="blank">Fact Find Compliance Report (Sample)</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Best%20Interest%20Duty%20File%20Note%20(Sample).pdf" target="blank">Best Interest Duty File Note (Sample)</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Client%20File%20Clean%20Up%20Report%20(Sample).pdf" target="blank">Client File Clean-Up Report (Sample)</a></li>
            <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Compliance%20File%20Review%20Report%20(Sample).pdf" target="blank">Compliance File Review Report (Sample)</a></li>
          </ul>
        </div>
      </div>
  </div>
  <br>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header">
          iComply2 Workflow Templates
        </div>
        <br>
        <div class="card-header">
          Threads
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Advice%20Process.xpl" target="blank">iComply2 SoA Process (Xplan Thread)</a></li>
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Annual%20Review%20Template.xpl" target="blank">iComply2 Annual Review Process (Xplan Thread)</a></li>
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/Importing%20Threads%20to%20Xplan.pdf" target="blank">Instructions on How to Import Threads to Xplan</a></li>
        </ul>
        <br>
        <div class="card-header">
          Asana
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20SoA%20Project%20Template.csv" target="blank">iComply2 SoA Process (Asana Template)</a></li>
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Client%20File%20Clean%20Up%20Report%20(Sample).pdf" target="blank">iComply2 Annual Review Process (Asana Template)</a></li>
          <li class="list-group-item"><a href="https://asana.com/guide/help/api/csv-importer" target="blank">Instructions on How to Import csv to Asana</a></li>
        </ul>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-header">
          iComply2 Site Configurations
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/Loading%20Basis%20of%20Advice%20into%20Xplan.pdf" target="blank">Loading Basis of Advice Text into Xplan</a></li>
          <li class="list-group-item"><a href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/Loading%20PDS%20Links%20into%20Xplan.pdf" target="blank">Loading PDS Links into Xplan</a></li>
        </ul>
      </div>
    </div>
  </div>

</div>
