import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {User} from '../models/User';
import {Observable} from 'rxjs';
import { AuthenticationService } from './authentication.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class WebService {
  API_URL =  'http://icomply2.pythonanywhere.com/api/v1/';
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  getUrl(resource: string): string {
    return this.API_URL + resource;
  }
  getHeaders(): object {
    let headers_ = new HttpHeaders();
    headers_ = headers_.set('Authorization', 'Bearer ' + this.authenticationService.currentUserValue.token);
    return {headers: headers_};
  }

  login(user: User): Observable<any> {
    return this.http.post(this.getUrl('tokens'), user, httpOptions);
  }
  signUp(user: User): Observable<any> {
    return this.http.post(this.getUrl('users'), user, httpOptions);
  }
  dashboard(): Observable<any> {
    return this.http.get(this.getUrl('dashboards'), this.getHeaders());
  }
}
