<!-- <div class="row">
  <div class="col">
  </div> -->
  <!-- <div class="col">
    <div class="text-center"><H2>PRACTICE MANAGEMENT</H2>
      <div class="row">
        <div class="col">
          <div class="text-right">
            <img src="./assets/updated_icons/compliance_borderless.png" class="img-fluid" alt="Responsive image">
          </div>
        </div>
        <div class="col">
          <div class="text-left">
            <img src="./assets/updated_icons/fds.png" class="img-fluid" alt="Responsive image">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-right">
            <img src="./assets/updated_icons/Revenue_borderless.PNG" class="img-fluid" alt="Responsive image">
          </div>
        </div>
        <div class="col">
          <img src="./assets/updated_icons/CPD_NEW_BOARDERLESS.jpg" class="img-fluid max-width-image" alt="Responsive image">
        </div>
        <div class="col">
          <div class="text-left">
            <img src="./assets/updated_icons/OBSERVATION_BORDERLESS.jpg" class="img-fluid max-width-image" alt="Responsive image">
          </div>
        </div>
      </div>
    </div>
    <div class="text-center"><H2>CLIENT MANAGEMENT</H2>
      <div class="row">
        <div class="col">
          <div class="text-right">
            <img src="./assets/updated_icons/IPS_borderless.jpg" class="img-fluid" alt="Responsive image">
          </div>
        </div>
        <div class="col">
          <div class="text-left">
            <img src="./assets/updated_icons/insurance_borderless.jpg" class="img-fluid" alt="Responsive image">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-right">
            <img src="./assets/updated_icons/TASKS_BORDERLESS.jpg" class="img-fluid max-width-image" alt="Responsive image">
          </div>
        </div>
        <div class="col">
          <div class="text-left">
            <img src="./assets/updated_icons/file_borderless.png" class="img-fluid" alt="Responsive image">
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- </div> -->
<app-navbar></app-navbar>
<div class="text-center">
  <!-- {{link}} -->
  <iframe width="1024" height="850" [src]='getUrl()' frameborder="0" allowFullScreen="true"></iframe>
</div>
