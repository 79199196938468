<app-navbar></app-navbar>
<div class="container">
  <br>
  <div class="card">
    <div class="card-body">
      <h1 class="text-center">iComply Dashboards</h1>
      <hr>
      <h4 class="text-center">iComply2 have built some new reports which we can make available for your dashboard</h4>
      <table class="table">
        <thead>
          <tr>
            <th style="width: 50%" scope="col"></th>
            <th style="width: 50%" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th  scope="row">GIF</th>
            <!-- <th  scope="row"><img src="../../../assets/CPD.gif" /></th> -->

            <td>
              <h5>CPD Dashboard:</h5>
              The CPD Dashboards allow you to track all your adviser&#39;s points across CPD, TASA, and FASEA onto one dashboard.
              <p>
                *Note: We have built a robot that will automatically sign into your supervisor Kaplan login and download the spreadsheet, then load this into your data. Therefore, you would need to authorise us to access this via your supervisor login.
              </p>
            </td>

          </tr>
          <tr>
            <th  scope="row">GIF</th>
            <!-- <th  scope="row"><img src="../../../assets/obs.gif" /></th> -->
            <td>
              <h5>Compliance Observations Dashboard</h5>
              <p>
                Once you have completed a Compliance File Review, the Observations Dashboard will track any remediation action items identified as a result of the review.
              </p>
              <p>
                The dashboard also helps you identify the following:
              </p>
              <ul>
                <li>The stats of the remediation action items.</li>
                <li>Which advice stage advisers may need further training.</li>
                <li>The most common observation types</li>
                <li>The severity of your obseration ratings</li>
              </ul>

            </td>

          </tr>
          <tr>
            <th  scope="row">GIF</th>
            <td>
              <h5>FDS & Fee For No Service Dashboard</h5>
              <p>
                We have recently added 4 new visuals to help manage FDS Letter due dates.
              </p>
              <ol>
                <li><strong>FDS Status</strong>: A drop down slicer which allows you to filter by FDS Status.</li>
                <li><strong>Number of FDS Now</strong>: Displays the number of FDS Letters Due this month.</li>
                <li><strong>Number of FDS Due Next Month</strong>: Displays the number of upcoming FDS Letters due next month to help manage your workloads.</li>
                <li><strong>Number of Overdue FDS</strong>: Counts the number of FDS Letters which are past their due date, but inside the 60 day period.</li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <br>
  <div class="card">
    <div class="card-body">
      <h1 class="text-center">iComply2 Support Desk</h1>
      <table class="table">
        <thead>
          <tr>
            <th style="width: 20%" scope="col"></th>
            <th style="width: 80%" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Ticketing System</th>
            <td>
              Updates include:
              <ul>
                <li>If you need to raise a ticket with an attachment, you can now send an email support@insightmp.com.au. You will receive a confirmation response with your ticket number for reference.</li>
                <li>We will also be shortly enabling users to view their tickets raised via the iComply2 Dashboards. This is the first step in our upgrade of the support desk.</li>
              </ul>
            </td>

          </tr>
          <tr>
            <th scope="row">iComply2 Resource Hub</th>
            <td>
              We have recently launched the iComply2 Resource Hub. The Resource Hub is where you are able to download Advice Templates, Sample Compliance Reports, Workflow Templates (Asana and Threads), and other site configuration instructions.
            </td>
          </tr>
          <tr>
            <th scope="row">iComply2 News</th>
            <td>
              At the beginning of each month, we will be releasing our latest newsletter on the latest iComply2 developments. these newsletters will also be available on the iComply2 News page. You can also view previous news releases from the iComply2 News page.
            </td>
          </tr>
        </tbody>
      </table>
      We will continue to release user guides, templates, and resources to the Hub as we develop them.


    </div>
  </div>
  <br>
  <div class="card">
    <div class="card-body">
      <h1 class="text-center">Other News & Future Developments</h1>
      <table class="table">
        <thead>
          <tr>
            <th style="width: 20%" scope="col"></th>
            <th style="width: 80%" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">News</th>
            <td>
              <ul>
                <li>Overall bug fixes, such as related entities and Power of Attorney information in 'Your Current Financial Position'.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <th scope="row">Upcoming Development Items</th>
            <td>
              <ul>
                <li>Clear and Concise SoA Template (estimated release end March 2020).</li>
                <li>FASEA Standards included in the Best Interest Duty File Note (estimated release end March 2020).</li>
                <li>SoA Vetting Tool (estimated release end April 2020).</li>
                <li>Automated loading of Revex data into Xplan (estimated release end March 2020).</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      If you have any feedback or comments with the content of this update and suggestions for future development, contact us through this <a routerLink="/support">link</a> and we will reply to your queries shortly
    </div>
  </div>
  <br>
  <div class="card">
    <div class="card-body">
      <ul>
        <li><a target="blank" href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Newsletters/2019/iComply2%20Newsletter%20-%20December%202019.pdf">December 2019</a></li>
        <li><a target="blank" href="https://ivana8ecf.blob.core.windows.net/icomplyblob/iComply2/iComply2%20Newsletters/2019/iComply2%20Newsletter%20-%20November%202019.pdf">November 2019</a></li>
      </ul>
    </div>
  </div>
  <br>
</div>
